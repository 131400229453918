<template>
  <div class="counter">
    <navbar title="贷款计算" />
    <van-tabs v-model="active" background="#FAFAFA" title-active-color="#00D3C4" title-inactive-color="#444" color="#00D3C4" line-width="56px" line-height="2px" @click="onClick" sticky>
      <van-tab v-for="(item, index) in tabsTitle" :title="item" :key="index">
        <div class="counter-box">
          <div class="counter-item" v-if=" status !=2">
            <div class="head">计算方式</div>
            <div class="header">
              <van-radio-group v-model="radio" direction="horizontal" @change="getValue">
                <van-radio name="1">根据面积单价</van-radio>
                <van-radio name="2">根据贷款额度</van-radio>
              </van-radio-group>
            </div>
            <hr />
          </div>

          <div class="activity" v-if="status == 0||status ==1">
            <div class="counter-item1">
              <div class="head">单价</div>
              <van-field v-model="unitPrice" placeholder="请输入" :rules="[{ required: true, message: '请输入单价' }]">
                <template #extra>
                <div class="momey">元/平方米</div>
              </template>
              </van-field>
             
            </div>
            <div class="counter-item2">
              <div class="head">面积</div>
              <van-field v-model="square" placeholder="请输入" :rules="[{ required: true, message: '请输入面积' }]">
                 <template #extra>
                <div class="momey">平方米</div>
              </template>
              </van-field>
              
            </div>
            <div class="coumter-item3">
              <div class="head">按揭成数</div>
              <div class="header">
                <van-dropdown-menu :overlay="false">
                  <van-dropdown-item v-model="number" :options="option1" />
                </van-dropdown-menu>
              </div>
            </div>

          </div>
          <div>
            <div v-if="status==3||status==4">
              <div class="counter-item4">
                <div class="head">贷款总额</div>
                <van-field v-model="loan" placeholder="请输入" :rules="[{ required: true, message: '请输入贷款总额' }]">
                   <template #extra>
                <div class="momey">万元</div>
              </template>
                </van-field>
               
              </div>
            </div>

            <div v-if="status == 2">
              <div class="counter-item5">
                <div class="head">商业贷款金额</div>
                <van-field v-model="sloan" placeholder="请输入" :rules="[{ required: true, message: '请输入贷款总额' }]">
                     <template #extra>
                <div class="momey">万元</div>
              </template>
                </van-field>
                
              </div>
              <div class="counter-item6">
                <div class="head">{{commLoan}}</div>
                <van-dropdown-menu :overlay="false">
                  <van-dropdown-item v-model="sRate" :options="option3" />
                </van-dropdown-menu>
              </div>
              <div class="counter-item7">
                <div class="head">公积金贷款</div>
                <van-field v-model="gloan" placeholder="请输入" :rules="[{ required: true, message: '请输入公积金贷款' }]">
                      <template #extra>
                <div class="momey">万元</div>
              </template>
                </van-field>
               
              </div>
              <div class="counter-item8">
                <div class="head">{{fundLoan}}</div>
                <van-dropdown-menu :overlay="false">
                  <van-dropdown-item v-model="gRate" :options="option5" />
                </van-dropdown-menu>
              </div>
            </div>
          </div>
          <div class="fixed">
            <div class="fixed-item">
              <div class="head">按揭年数</div>
              <van-dropdown-menu :overlay="false">
                <van-dropdown-item v-model="year" :options="option2" />
              </van-dropdown-menu>
            </div>
            <div class="fixed-item1" v-if="status==0||status ==3">
              <div class="head">{{profit}}</div>
              <van-dropdown-menu :overlay="false">
                <van-dropdown-item v-model="sRate" :options="option3" />
              </van-dropdown-menu>
            </div>
            <div class="fixed-item1" v-if="status==1||status ==4">
              <div class="head">{{profit}}</div>
              <van-dropdown-menu :overlay="false">
                <van-dropdown-item v-model="gRate" :options="option5" />
              </van-dropdown-menu>
            </div>
            <div class="fixed-item2">
              <div class="head">还款方式</div>
              <van-dropdown-menu :overlay="false">
                <van-dropdown-item v-model="mode" :options="option4" />
              </van-dropdown-menu>
            </div>
            <div class="fixed-item3">
              <div class="inner">
                <span class="inner-box">
                  <van-button type="info" class="reset-btn" name="button1" @click="clearValue">重新填写</van-button>
                </span>
                <span>
                  <van-button type="info" :class="btnActive?'active calculation-btn':'calculation-btn'" name="button2" @click="calculation">开始计算</van-button>
                </span>
              </div>
              <div class="fixed-item4">
                <div class="bottom">计算结果</div>
              </div>
              <div class="bottom-head">
                <div class="bottom-item1">房屋总价<span class="bottom-item1-inner1">{{totalHousePeice}}<span v-if="flag">元</span></span></div>
                <div class="bottom-item1">贷款总额<span class="bottom-item1-inner1">{{totalLoan}}元</span></div>
                <div class="bottom-item1">还款总额<span class="bottom-item1-inner1">{{totalRepayment}}元</span></div>
                <div class="bottom-item1">支付利息<span class="bottom-item1-inner1">{{interestPayment}}元</span></div>
                <div class="bottom-item1">首期付款<span class="bottom-item1-inner1">{{intialPayment}}元</span></div>
                <div class="bottom-item1">贷款月数<span class="bottom-item1-inner1">{{loanMonths}}月</span></div>
                <div v-if="mode==1">
                  <div class="bottom-item1">月均还款<span class="bottom-item1-inner1">{{averageMonthlyRepayment}}元</span></div>
                </div>
                <div v-if="mode==0">
                  <div class="bottom-item1">月均还款<div class="scrollar-box">
                      <div class="scrollar" v-for="(item,index) in arr" :key="index">第{{item.num}}个月还款{{item.endnum}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";

export default {
  name: "Counter",
  data() {
    return {
      active: 0,
      tabsTitle: ["商业贷款", "公积金贷款", "组合贷款"],
      status: 0,
      radio: "1",
      unitPrice: "",
      square: "",
      loan: "",
      sloan: "",
      gloan: "",
      number: 0.9,
      option1: [
        { text: "九成", value: 0.9 },
        { text: "八成", value: 0.8 },
        { text: "七成", value: 0.7 },
        { text: "六成", value: 0.6 },
        { text: "五成", value: 0.5 },
        { text: "四成", value: 0.4 },
        { text: "三成", value: 0.3 },
        { text: "二成", value: 0.2 },
      ],
      year: 12,
      option2: [
        { text: "一年（12期）", value: 12 },
        { text: "两年（24期）", value: 24 },
        { text: "三年（36期）", value: 36 },
        { text: "四年（48期）", value: 48 },
        { text: "五年（60期）", value: 60 },
        { text: "六年（72期）", value: 72 },
        { text: "七年（84期）", value: 84 },
        { text: "八年（96期）", value: 96 },
        { text: "九年（108期）", value: 108 },
        { text: "十年（120期）", value: 120 },
        { text: "十五年（180期）", value: 180 },
        { text: "二十年（240期）", value: 240 },
        { text: "二十五年（300期）", value: 300 },
        { text: "三十年（360期）", value: 360 },
      ],
      sRate: 0.055,
      option3: [
        // { text: "最新基准利率", value: 0.055 },
        // { text: "最新基准利率8.5折", value: 0.0468 },
        // { text: "最新基准利率8.8折", value: 0.484 },
        // { text: "最新基准利率9折", value: 0.0495 },
        // { text: "最新基准利率1.1倍", value: 0.0605 },
        // { text: "最新基准利率1.2倍", value: 0.066 },
        // { text: "最新基准利率1.3倍", value: 0.0715 },
      ],
      mode: 1,
      option4: [
        { text: "等额本金", value: 0 },
        { text: "等额本息", value: 1 },
      ],
      gRate: 0.0325,
      option5: [
        // { text: "最新基准利率", value: 0.0325 },
        // { text: "最新基准利率7折", value: 0.0228 },
        // { text: "最新基准利率8.5折", value: 0.0276 },
        // { text: "最新基准利率1.1倍", value: 0.0358 },
        // { text: "最新基准利率1.2倍", value: 0.039 },
        // { text: "最新基准利率1.3倍", value: 0.0423 },
      ],
      totalHousePeice: 0,
      totalLoan: 0,
      totalRepayment: 0,
      interestPayment: 0,
      intialPayment: 0,
      loanMonths: 0,
      averageMonthlyRepayment: 0,
      btnActive: false,
      arr: [],
      flag: true,
      profit:'',
      commLoan:'',
      fundLoan:'',
    };
  },

  mounted() {
    this.onClick(0);
  },

  methods: {

    getTheloan(){

    },

    onClick(name) {
      const data={
        type:name
      }
      this.$post("user/getloanProfit",data).then((res)=>{
        if (res.status==200){
          if (res.data.type==1){
            this.option3 = res.data.mapList
            this.profit = '贷款利率(最新基准利率'+res.data.profit+'%)';
            for (var i=0;i<this.option3.length;i++){
              console.log(this.option3[i])
              this.sRate = this.option3[0].value
            }
          }else if(res.data.type==2){
            this.profit = '贷款利率(最新基准利率'+res.data.profit+'%)';
            this.option5 = res.data.mapList
            for (var i=0;i<this.option5.length;i++){
              this.gRate = this.option5[0].value
            }
          }else if(res.data.type==3){
            this.commLoan = "商业贷款利率(最新基准利率"+res.data.commLoan+"%)"
            this.fundLoan = "公积金贷款利率(最新基准利率"+res.data.fundLoan+"%)"
            this.option3 = res.data.businessList
            for (var i=0;i<this.option3.length;i++){
              this.sRate = this.option3[0].value
            }
            this.option5 = res.data.accumulationFund
            for (var i=0;i<this.option5.length;i++){
              this.gRate = this.option5[0].value
            }
          }
        }
      })
      this.flag = true;
      this.status = name;
      this.radio = "1";
      this.totalHousePeice = 0;
      this.totalLoan = 0;
      this.totalRepayment = 0;
      this.interestPayment = 0;
      this.intialPayment = 0;
      this.loanMonths = 0;
      this.averageMonthlyRepayment = 0;
      this.arr = [];
      this.mode = 1;
    },
    getValue(name) {
      this.flag = true;

      if (this.status == 0 || this.status == 3) {
        this.status = name == 1 ? 0 : 3;
      } else {
        this.status = name == 1 ? 1 : 4;
      }
    },
    clearValue() {
      this.unitPrice = "";
      this.square = "";
      this.loan = "";
      this.sloan = "";
      this.gloan = "";
      this.arr = [];
      this.totalHousePeice = 0;
      this.totalLoan = 0;
      this.totalRepayment = 0;
      this.interestPayment = 0;
      this.intialPayment = 0;
      this.loanMonths = 0;
      this.averageMonthlyRepayment = 0;
      this.arr = [];
      this.mode = 1;
      this.btnActive = false;
    },
    calculation() {
      this.arr = [];

      this.btnActive = true;
      if (this.status == 0) {
        //房屋总价
        this.totalHousePeice = (this.unitPrice * this.square).toFixed(2);
        //贷款总额
         this.totalLoan = (this.totalHousePeice * this.number).toFixed(2);
        //贷款月数
        this.loanMonths = this.year;
        //首期付款
       this.intialPayment = (this.totalHousePeice - this.totalLoan).toFixed(2);
        if (this.mode == 1) {
          //月均还款?
          //1.月利率
          var rate = this.sRate / 12;
          var rate1 = 1 + rate;
          var rate2 = Math.pow(rate1, this.loanMonths);
          this.averageMonthlyRepayment = (
            (this.totalLoan * rate * rate2) /
            (rate2 - 1)
          ).toFixed(2);
          //还款总额
          this.totalRepayment = (
            this.averageMonthlyRepayment * this.loanMonths
          ).toFixed(2);
          //支付利息?
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        } else {
          // //贷款总价=（房屋总价*按揭成数）
          // var totalLoan1 = (this.totalHousePeice * this.number).toFixed(2);
          //月利率
          var rate = this.sRate / 12;
          //每月还款额
          var loanamount = "";
          //每月还款额
          var endNum = 0;
          //还款总额
          var totalAmount = 0;
          // 贷款总额：this.totalLoan
          for (let i = 0; i < this.loanMonths; i++) {
            endNum =
              this.totalLoan / this.loanMonths +
              (this.totalLoan - (this.totalLoan / this.loanMonths) * i) * rate;
            var endnum = endNum.toFixed(2);
            totalAmount += parseFloat(endnum);
            this.arr.push({ num: i + 1, endnum });
          }
          this.totalRepayment = totalAmount.toFixed(2);
          // 利息=还款总额-房贷总额
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        }
      } else if (this.status == 1) {
        //房屋总价
        this.totalHousePeice = (this.unitPrice * this.square).toFixed(2);
        //贷款总额
       this.totalLoan = (this.totalHousePeice * this.number).toFixed(2);
        //贷款月数
        this.loanMonths = this.year;
        //首期付款
       this.intialPayment = (this.totalHousePeice - this.totalLoan).toFixed(2);
        if (this.mode == 1) {
          //月均还款?
          //1.月利率
          var rate = this.gRate / 12;
          var rate1 = 1 + rate;
          var rate2 = Math.pow(rate1, this.loanMonths);
          this.averageMonthlyRepayment = (
            (this.totalLoan * rate * rate2) /
            (rate2 - 1)
          ).toFixed(2);
          console.log(rate);
          //还款总额
          this.totalRepayment = (
            this.averageMonthlyRepayment * this.loanMonths
          ).toFixed(2);
          //支付利息?
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        } else {
          // //贷款总价=（房屋总价*按揭成数）
          // var totalLoan1 = this.totalHousePeice * this.number;
          //月利率
          var rate = this.gRate / 12;
          //每月还款额
          var loanamount = "";
          //每月还款额
          var endNum = 0;
          //还款总额
          var totalAmount = 0;
          // 贷款总额：this.totalLoan
          for (let i = 0; i < this.loanMonths; i++) {
            endNum =
              this.totalLoan / this.loanMonths +
              (this.totalLoan - (this.totalLoan / this.loanMonths) * i) * rate;
            var endnum = endNum.toFixed(2);
            totalAmount += parseFloat(endnum);
            this.arr.push({ num: i + 1, endnum });
          }
          this.totalRepayment = totalAmount.toFixed(2);
          // 利息=还款总额-房贷总额
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        }
      } else if (this.status == 2) {
        let sloan01 = this.sloan;
        let gloan01 = this.gloan;
        this.flag = false;

        //贷款月数
        this.loanMonths = this.year;

        //房屋总价
        this.totalHousePeice = "略";
        //首期付款
        this.intialPayment = 0;
        if (this.mode == 1) {
          if (sloan01 == "") sloan01 = 0;
          if (gloan01 == "") gloan01 = 0;
          //商业贷款部分
          var smoney = parseFloat(sloan01) * 10000;
          console.log(sloan01);
          var rate = parseFloat(this.sRate / 12);
          var rate1 = 1 + rate;
          var rate2 = Math.pow(rate1, this.loanMonths);
          var loanamount = (smoney * rate * rate2) / (rate2 - 1);
          loanamount = parseFloat(loanamount);
          //还款总额
          var totalAmount = parseFloat(loanamount * this.loanMonths);

          //公积金贷款部分
          var gmoney = parseFloat(gloan01) * 10000;
          var grate = parseFloat(this.gRate / 12);
          var grate1 = 1 + grate;
          var grate2 = Math.pow(grate1, this.loanMonths);
          var loanamount1 = parseFloat(
            (gmoney * grate * grate2) / (grate2 - 1)
          );
          //还款总额
          var totalAmount1 = parseFloat(loanamount1 * this.loanMonths);

          //计算结果
          //贷款总额
          this.totalLoan = (smoney + gmoney).toFixed(2);
          //月均还款
          this.averageMonthlyRepayment = (loanamount + loanamount1).toFixed(2);
          //还款总额
          this.totalRepayment = (totalAmount + totalAmount1).toFixed(2);

          //利息
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        } else {
          if (sloan01 == "") sloan01 = 0;
          if (gloan01 == "") gloan01 = 0;
          //商业贷部分
          var smoney = parseFloat(sloan01) * 10000;
          var rate = parseFloat(this.sRate / 12);
          var loanamount = "";
          var endNum = 0;
          var totalAmount = 0;
          //公积金贷部分
          var gmoney = parseFloat(gloan01) * 10000;
          var rate1 = parseFloat(this.gRate / 12);
          var endNum1 = 0;
          var totalAmount1 = 0;
          for (var i = 0; i < this.loanMonths; i++) {
            //商业贷每月还款
            endNum =
              smoney / this.loanMonths +
              (smoney - (smoney / this.loanMonths) * i) * rate;
            //公积金贷每月还款
            endNum1 =
              gmoney / this.loanMonths +
              (gmoney - (gmoney / this.loanMonths) * i) * rate1;
            var endnum = endNum + endNum1;

            totalAmount += endNum;
            totalAmount1 += endNum1;
            this.arr.push({ num: i + 1, endnum: endnum.toFixed(2) });
          }
          //计算结果
          //贷款总额
          this.totalLoan = (smoney + gmoney).toFixed(2);
          //月均还款
          this.averageMonthlyRepayment = (endNum + endNum1).toFixed(2);
          //还款总额
          this.totalRepayment = (totalAmount + totalAmount1).toFixed(2);

          //利息
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        }
      } else if (this.status == 3) {
        let loan01 = this.loan;
        this.flag = false;
        //贷款月数
        this.loanMonths = this.year;
        //房屋总价
        this.totalHousePeice = "略";
        //首期付款
        this.intialPayment = 0;
        if (this.mode == 1) {
          if (loan01 == "") loan01 = 0;
          //贷款金额
          this.totalLoan = parseFloat(loan01) * 10000;
          console.log(loan01);
          //月均还款
          var rate = this.sRate / 12;
          var rate1 = 1 + rate;
          var rate2 = Math.pow(rate1, this.loanMonths);
          this.averageMonthlyRepayment = (
            (this.totalLoan * rate * rate2) /
            (rate2 - 1)
          ).toFixed(2);
          this.averageMonthlyRepayment = parseFloat(
            this.averageMonthlyRepayment
          );
          //还款总额
          this.totalRepayment = (
            this.averageMonthlyRepayment * this.loanMonths
          ).toFixed(2);
          this.totalRepayment = parseFloat(this.totalRepayment);
          //支付利息?
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        } else {
          if (loan01 == "") loan01 = 0;
          //贷款金额
          this.totalLoan = parseFloat(loan01) * 10000;
          //月利率
          var rate = this.sRate / 12;
          //每月还款额
          var loanamount = "";
          //每月还款额
          var endNum = 0;
          //还款总额
          var totalAmount = 0;
          // 贷款总额：this.totalLoan
          for (let i = 0; i < this.loanMonths; i++) {
            endNum =
              this.totalLoan / this.loanMonths +
              (this.totalLoan - (this.totalLoan / this.loanMonths) * i) * rate;
            var endnum = endNum.toFixed(2);
            totalAmount += parseFloat(endnum);
            this.arr.push({ num: i + 1, endnum });
          }
          this.totalRepayment = totalAmount.toFixed(2);
          // 利息=还款总额-房贷总额
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        }
      } else if (this.status == 4) {
        this.flag = false;
        let loan01 = this.loan;
        //贷款月数
        this.loanMonths = this.year;
        //房屋总价
        this.totalHousePeice = "略";
        //首期付款
        this.intialPayment = 0;
        if (this.mode == 1) {
          if (loan01 == "") loan01 = 0;
          //贷款金额
          this.totalLoan = parseFloat(loan01) * 10000;
          console.log(loan01);
          //月均还款
          var rate = this.gRate / 12;
          var rate1 = 1 + rate;
          var rate2 = Math.pow(rate1, this.loanMonths);
          this.averageMonthlyRepayment = (
            (this.totalLoan * rate * rate2) /
            (rate2 - 1)
          ).toFixed(2);
          this.averageMonthlyRepayment = parseFloat(
            this.averageMonthlyRepayment
          );
          //还款总额
          this.totalRepayment = (
            this.averageMonthlyRepayment * this.loanMonths
          ).toFixed(2);
          this.totalRepayment = parseFloat(this.totalRepayment);
          //支付利息?
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        } else {
          if (loan01 == "") loan01 = 0;
          //贷款金额
          this.totalLoan = parseFloat(loan01) * 10000;
          //月利率
          var rate = this.gRate / 12;
          //每月还款额
          var loanamount = "";
          //每月还款额
          var endNum = 0;
          //还款总额
          var totalAmount = 0;
          // 贷款总额：this.totalLoan
          for (let i = 0; i < this.loanMonths; i++) {
            endNum =
              this.totalLoan / this.loanMonths +
              (this.totalLoan - (this.totalLoan / this.loanMonths) * i) * rate;
            var endnum = endNum.toFixed(2);
            totalAmount += parseFloat(endnum);
            this.arr.push({ num: i + 1, endnum });
          }
          this.totalRepayment = totalAmount.toFixed(2);
          // 利息=还款总额-房贷总额
          this.interestPayment = (this.totalRepayment - this.totalLoan).toFixed(
            2
          );
        }
      }
    },
  },

  components: {
    navbar,
  },
};
</script>



<style lang="less" scoped>
.counter {
  /deep/.van-dropdown-item {
    margin: 0 18px;
    .van-popup--top {
      top: 1px;
    }
    .van-cell {
      border: 1px solid #ddd;
    }
  }
  /deep/.van-dropdown-menu__title {
    width: 100%;
    &::after {
      right: 10px;
    }
  }

  /deep/.van-tabs {
    .van-tabs__wrap {
      border-bottom: 1px solid #ddd;

      .van-tab__text {
        font-size: 16px;
      }
    }
  }

  .counter-box {
    padding: 10px 18px;
  }
  .counter-item {
    .header {
      font-size: 16px;
      margin: 15px;
    }
  }

  .counter-item1 {
    .van-field {
      font-size: 16px;
      border: 1px solid #dbd9d9;
      margin-top: 5px;
      height: 40px;
      margin-bottom: 5px;
    }
    .money {
      font-size: 15px;
        // margin-left: 10px;
    }
  }
  .counter-item2 {
    .van-field {
      font-size: 16px;
      border: 1px solid #dbd9d9;
      margin-top: 5px;
      height: 40px;
      margin-bottom: 5px;
    }
    .money {
      font-size: 15px;
      margin-left: 10px;
    }
  }
  .counter-item3 {
    .header {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .counter-item4 {
    .van-field {
      font-size: 16px;
      border: 1px solid #dbd9d9;
      margin-top: 5px;
      height: 40px;
      margin-bottom: 5px;
    }
    .money {
      font-size: 15px;
       margin-left: 10px;
    }
  }
  .counter-item5 {
    .van-field {
      font-size: 16px;
      border: 1px solid #dbd9d9;
      margin-top: 5px;
      height: 40px;
      margin-bottom: 5px;
    }
    .money {
      font-size: 15px;
       margin-left: 10px;
    }
  }
  .counter-item6 {
    .head {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .counter-item7 {
    .van-field {
      font-size: 16px;
      border: 1px solid #dbd9d9;
      margin-top: 5px;
      height: 40px;
      margin-bottom: 5px;
    }
    .money {
      font-size: 15px;
        margin-left: 10px;
    }
  }
  .counter-item8 {
    .head {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .fixed-item {
    .head {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .fixed-item1 {
    .head {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .fixed-item2 {
    .head {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .fixed-item3 {
    .inner {
      margin-top: 20px;
    }
    .inner-box {
      margin-right: 38px;
      margin-top: 10px;
    }
    .reset-btn {
      width: 150px;
      background: #00d3c4;
    }
    .calculation-btn {
      width: 150px;
      background: rgb(165, 165, 165);
      &.active {
        background-color: orange;
      }
    }
  }
}
.head {
  font-size: 15px;
  color: #00d3c4;
}
.bottom-head {
  font-size: 16px;
}
.bottom-item1 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.bottom-item1-inner1 {
  float: right;
}
.scrollar-box {
  height: 120px;
  overflow-y: auto;
  position: relative;
  top: -20px;
}
.scrollar {
  float: right;
  border: 1px solid rgb(165, 165, 165);
  width: 175px;
}
.bottom {
  font-size: 15px;
  color: #00d3c4;
  margin-top: 10px;
}
hr {
  margin: 0 auto;
  width: 333px;
  background-color: #dbd9d9;
  height: 1px;
  border: 0;
}
/deep/.van-dropdown-menu {
  border: 1px solid #dbd9d9;
  .van-dropdown-menu__bar {
    height: 40px;
  }
}

/deep/.van-button {
  border: none;
  height: 40px;
  font-size: 17px;
}
</style>